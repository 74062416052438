<template>
  <div v-if="!renderNow" id="loading-wrapper">
    <div id="loading-text">LOADING</div>
    <div id="loading-content"></div>
  </div>
  <v-container v-else :class="classes.main">
    <v-row no-gutters>
      <v-col :cols="cols.title">
        <p class="ft-bold mb-3 xl:text-2xl lg:text-2xl md:text-2xl sm:text-lg">
          Welcome to <span class="primary--text">Course Design</span>
        </p>
        <p class="grey--text xl:text-base lg:text-base md:text-base sm:text-sm">
          Start designing your course by creating modules
        </p>
      </v-col>
      <v-col
        :cols="cols.button"
        :class="{ 'mt-3': screenWidth < 600 }"
        class="d-flex align-center justify-end"
      >
        <v-menu offset-y>
          <template #activator="{ on , attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              depressed
              color="primary"
              class="white--text"
              :large="screenWidth >= 600"
              :block="screenWidth - $vuetify.application.left < 200"
            >
              <v-icon>mdi-plus</v-icon>
              <span
                class="ml-1 xl:text-base lg:text-base md:text-base sm:text-base"
                style="font-weight: 500"
                >Create a outline</span
              >
            </v-btn>
          </template>
          <v-list>
            <v-list-item class="py-0" @click="showModuleCreatePanel = true">
              <v-list-item-title class="clickable"
                >Create Module
              </v-list-item-title>
            </v-list-item>
            <v-list-item class="py-0" @click="showSectionCreatePanel = true">
              <v-list-item-title class="clickable"
                >Create Section
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <course-organization-component
      ref="organization"
      class="mt-5"
      :course-id="courseId"
      :owner-id="partner_code"
      @update="refreshData"
    ></course-organization-component>
    <v-dialog v-model="showModuleCreatePanel" width="unset">
      <create-module-panel
        v-model="modules"
        :course-id="courseId"
        :loading="saving"
        @save="saveModule"
        @close="showModuleCreatePanel = false"
      ></create-module-panel>
    </v-dialog>
    <v-dialog v-model="showSectionCreatePanel" width="unset">
      <create-section-panel
        v-model="sections"
        :course-id="courseId"
        :loading="saving"
        @save="saveSection"
        @close="showSectionCreatePanel = false"
      ></create-section-panel>
    </v-dialog>
  </v-container>
</template>

<script>
import CreateModulePanel from "#ecf/course-design/components/CreateModule/CreateModulePanel.vue";
import CreateSectionPanel from "#ecf/course-design/components/CreateSection/CreateSectionPanel.vue";
import CourseOrganizationComponent from "#ecf/course-design/components/CourseOrganization/CourseOrganizationComponent.vue";
import { CourseDesignUtils } from "#ecf/course-design/mixins";

export default {
  name: "CourseDesign",
  props: ["courseId", "partner_code"],
  components: {
    CreateModulePanel,
    CreateSectionPanel,
    CourseOrganizationComponent
  },
  mixins: [CourseDesignUtils],
  async created() {
    try {
      await this.retrieveStoresAndExams();
      await this.retrieveModules();
    } catch (e) {
      this.$root.$emit("alert", [undefined, e.message]);
    } finally {
      this.modules = {
        title: undefined,
        contents: undefined
      };
      this.sections = {
        title: undefined,
        contents: undefined
      };
      this.renderNow = true;
    }
  },
  data() {
    return {
      renderNow: false,
      loadingContent: false,
      showModuleCreatePanel: false,
      showSectionCreatePanel: false,
      modules: undefined,
      sections: undefined,
      saving: false
    };
  },
  computed: {
    classes() {
      return {
        main: `pa-${this.breakPointValues(0, 2, 4, 8)}`
      };
    },
    cols() {
      return {
        title: this.screenWidth < 600 ? "12" : "8",
        button: this.screenWidth < 600 ? "12" : "4"
      };
    }
  },
  methods: {
    async retrieveStoresAndExams() {
      await this.$store.dispatch("design/retrieveStoresAndExams", {
        courseId: this.courseId,
        ownerId: this.partner_code
      });
    },
    async retrieveModules() {
      if (this.courseId in this.$store.getters["design/modules"]) return;

      await this.$store.dispatch("design/getCourseModules", {
        courseId: this.courseId,
        ownerId: this.partner_code
      });
    },
    async refreshData() {
      try {
        this.renderNow = false;
        await Promise.all([
          // this.$store.dispatch("design/retrieveStoresAndExams", {
          //   courseId: this.courseId,
          //   ownerId: this.partner_code
          // }),
          this.$store.dispatch("design/getCourseModules", {
            courseId: this.courseId,
            ownerId: this.partner_code
          }),
          this.$store.dispatch("design/getCourseSections", {
            courseId: this.courseId,
            ownerId: this.partner_code
          })
        ]);
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.renderNow = true;
      }
    },
    async saveModule() {
      if (this.saving) return;
      try {
        this.saving = true;
        await this.$store.dispatch("design/saveModule", {
          title: this.modules.title,
          contents: this.modules.contents,
          courseId: this.courseId,
          ownerId: this.partner_code
        });
        this.$root.$emit("alert", ["Success", "Module was saved successfully"]);
        await this.refreshData();
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.saving = false;
        this.showModuleCreatePanel = false;
      }
    },
    async saveSection() {
      if (this.saving) return;
      try {
        this.saving = true;
        await this.$store.dispatch("design/saveSection", {
          title: this.sections.title,
          contents: this.sections.contents,
          courseId: this.courseId,
          ownerId: this.partner_code
        });
        this.$root.$emit("alert", [
          "Success",
          "Section was saved successfully"
        ]);
        await this.refreshData();
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.saving = false;
        this.showSectionCreatePanel = false;
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
