var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.renderNow
    ? _c("div", { attrs: { id: "loading-wrapper" } }, [
        _c("div", { attrs: { id: "loading-text" } }, [_vm._v("LOADING")]),
        _c("div", { attrs: { id: "loading-content" } }),
      ])
    : _c(
        "v-container",
        { class: _vm.classes.main },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c("v-col", { attrs: { cols: _vm.cols.title } }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "ft-bold mb-3 xl:text-2xl lg:text-2xl md:text-2xl sm:text-lg",
                  },
                  [
                    _vm._v(" Welcome to "),
                    _c("span", { staticClass: "primary--text" }, [
                      _vm._v("Course Design"),
                    ]),
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass:
                      "grey--text xl:text-base lg:text-base md:text-base sm:text-sm",
                  },
                  [_vm._v(" Start designing your course by creating modules ")]
                ),
              ]),
              _c(
                "v-col",
                {
                  staticClass: "d-flex align-center justify-end",
                  class: { "mt-3": _vm.screenWidth < 600 },
                  attrs: { cols: _vm.cols.button },
                },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: { "offset-y": "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "white--text",
                                      attrs: {
                                        depressed: "",
                                        color: "primary",
                                        large: _vm.screenWidth >= 600,
                                        block:
                                          _vm.screenWidth -
                                            _vm.$vuetify.application.left <
                                          200,
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("v-icon", [_vm._v("mdi-plus")]),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "ml-1 xl:text-base lg:text-base md:text-base sm:text-base",
                                      staticStyle: { "font-weight": "500" },
                                    },
                                    [_vm._v("Create a outline")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item",
                            {
                              staticClass: "py-0",
                              on: {
                                click: function ($event) {
                                  _vm.showModuleCreatePanel = true
                                },
                              },
                            },
                            [
                              _c(
                                "v-list-item-title",
                                { staticClass: "clickable" },
                                [_vm._v("Create Module ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              staticClass: "py-0",
                              on: {
                                click: function ($event) {
                                  _vm.showSectionCreatePanel = true
                                },
                              },
                            },
                            [
                              _c(
                                "v-list-item-title",
                                { staticClass: "clickable" },
                                [_vm._v("Create Section ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("course-organization-component", {
            ref: "organization",
            staticClass: "mt-5",
            attrs: { "course-id": _vm.courseId, "owner-id": _vm.partner_code },
            on: { update: _vm.refreshData },
          }),
          _c(
            "v-dialog",
            {
              attrs: { width: "unset" },
              model: {
                value: _vm.showModuleCreatePanel,
                callback: function ($$v) {
                  _vm.showModuleCreatePanel = $$v
                },
                expression: "showModuleCreatePanel",
              },
            },
            [
              _c("create-module-panel", {
                attrs: { "course-id": _vm.courseId, loading: _vm.saving },
                on: {
                  save: _vm.saveModule,
                  close: function ($event) {
                    _vm.showModuleCreatePanel = false
                  },
                },
                model: {
                  value: _vm.modules,
                  callback: function ($$v) {
                    _vm.modules = $$v
                  },
                  expression: "modules",
                },
              }),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { width: "unset" },
              model: {
                value: _vm.showSectionCreatePanel,
                callback: function ($$v) {
                  _vm.showSectionCreatePanel = $$v
                },
                expression: "showSectionCreatePanel",
              },
            },
            [
              _c("create-section-panel", {
                attrs: { "course-id": _vm.courseId, loading: _vm.saving },
                on: {
                  save: _vm.saveSection,
                  close: function ($event) {
                    _vm.showSectionCreatePanel = false
                  },
                },
                model: {
                  value: _vm.sections,
                  callback: function ($$v) {
                    _vm.sections = $$v
                  },
                  expression: "sections",
                },
              }),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }